<template>
  <div class="public">
    <div class="container HomeAcceptance">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="idiot-one-box">
          <div class="idiot-one-box-top">
            <div
              class="idiot-one-top-child"
              v-for="(item, i) in liuchengdata"
              :key="i"
              @click="idiotone(item, i)"
              :style="{ color: liuchengdataindex == i ? '#1d67ff' : '#232a24' }"
            >
              <div class="idiot-one-top-child-icon"></div>
              <div
                class="idiot-one-top-child-newicon"
                :style="{ display: liuchengdataindex == i ? 'block' : 'none' }"
              ></div>
              <div class="idiot-one-top-child-line"></div>

              <div class="idiot-one-top-child-val">
                {{ item.val }}
                <div class="idiot-one-top-child-com">{{ item.com }}</div>
              </div>
              <div class="idiot-one-top-child-name">{{ item.name }}</div>
            </div>
          </div>
          <div class="toptab_val_box">
            <div
              class="toptab_val_child"
              v-for="(item, ind) in newforCodeData"
              :key="ind"
            >
              <div class="toptab_val_child_name">{{ item.name }}</div>
              <div class="toptab_val_child_val">
                {{ item.data[liuchengdataindex] }}
                <div class="toptab_val_child_com">件</div>
                <i
                  :class="
                    item.data[liuchengdataindex] > 0
                      ? 'el-icon-top t'
                      : item.data[liuchengdataindex] < 0
                      ? 'el-icon-bottom b'
                      : ''
                  "
                ></i>
              </div>
              <div class="toptab_val_child_bfb">
                {{ item.bdata[liuchengdataindex] }}
                <div class="toptab_val_child_com">%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 投诉受理新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理新增变化趋势 </span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal"></KcSelect>
        </div>
        <div class="charts">
          <LineChart
            v-loading="lineChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData1"
            :legendData="legendData1"
            :units="unitData"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <!-- <Tab :optionData="tabData" @popupswitch="popupVal2"></Tab> -->
      </div>
      <!-- 投诉受理累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理累计变化趋势 </span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal3"></KcSelect>
        </div>
        <div class="charts">
          <LineChart
            v-loading="lineChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="lineChartData2"
            :legendData="legendData2"
            :units="unitData2"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <!-- <Tab :optionData="tabData" @popupswitch="popupVal4"></Tab> -->
      </div>
      <!-- 投诉受理按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉处理区域分布数量统计</span>
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="options"
            right="10"
            top="-35"
            @popupVal="popupVal6"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              fixed
              :width="0.18 * $bodyWidth"
            >
            </el-table-column>
            <el-table-column
              :width="0.32 * $bodyWidth"
              prop="complainNum"
              :key="1"
              label="已投诉"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.compaintNum }}</span
                ><i
                  :class="
                    scope.row.complainNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.complainNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.complainNumSign > 0
                      ? "+" + scope.row.complainNumSign
                      : scope.row.complainNumSign < 0
                      ? "" + scope.row.complainNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="acceptNum"
              :width="0.32 * $bodyWidth"
              :key="2"
              label="待审核"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.pendingNum }}</span
                ><i
                  :class="
                    scope.row.pendingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.pendingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.pendingNumSign > 0
                      ? "+" + scope.row.pendingNumSign
                      : scope.row.pendingNumSign < 0
                      ? "" + scope.row.pendingNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="3"
              :width="0.32 * $bodyWidth"
              label="待分配"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.distributionNum }}</span
                ><i
                  :class="
                    scope.row.distributionNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.distributionNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.distributionNumSign > 0
                      ? "+" + scope.row.distributionNumSign
                      : scope.row.distributionNumSign < 0
                      ? "" + scope.row.distributionNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="4"
              :width="0.32 * $bodyWidth"
              label="处理中"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.processingNum }}</span
                ><i
                  :class="
                    scope.row.processingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.processingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.processingNumSign > 0
                      ? "+" + scope.row.processingNumSign
                      : scope.row.processingNumSign < 0
                      ? "" + scope.row.processingNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="5"
              :width="0.32 * $bodyWidth"
              label="已处理"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.dealNum }}</span
                ><i
                  :class="
                    scope.row.dealNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.dealNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.dealNumSign > 0
                      ? "+" + scope.row.dealNumSign
                      : scope.row.dealNumSign < 0
                      ? "" + scope.row.dealNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="6"
              :width="0.32 * $bodyWidth"
              label="已撤销"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.cancelNum }}</span
                ><i
                  :class="
                    scope.row.cancelNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.cancelNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.cancelNumSign > 0
                      ? "+" + scope.row.cancelNumSign
                      : scope.row.cancelNumSign < 0
                      ? "" + scope.row.cancelNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 投诉受理按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>投诉受理领域/行业分布数量统计</span>
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="options"
            right="10"
            top="-35"
            @popupVal="popupVal8"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="industryName"
              :key="0"
              label="领域/行业"
              sortable
              :width="0.32 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <el-table-column
              prop="complainNum"
              :key="1"
              label="已投诉"
              :width="0.32 * $bodyWidth"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.compaintNum }}</span
                ><i
                  :class="
                    scope.row.complainNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.complainNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.complainNumSign > 0
                      ? "+" + scope.row.complainNumSign
                      : scope.row.complainNumSign < 0
                      ? "" + scope.row.complainNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="acceptNum"
              :key="2"
              :width="0.32 * $bodyWidth"
              label="待审核"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.pendingNum }}</span
                ><i
                  :class="
                    scope.row.pendingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.pendingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.pendingNumSign > 0
                      ? "+" + scope.row.pendingNumSign
                      : scope.row.pendingNumSign < 0
                      ? "" + scope.row.pendingNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="3"
              :width="0.32 * $bodyWidth"
              label="待分配"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.distributionNum }}</span
                ><i
                  :class="
                    scope.row.distributionNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.distributionNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.distributionNumSign > 0
                      ? "+" + scope.row.distributionNumSign
                      : scope.row.distributionNumSign < 0
                      ? "" + scope.row.distributionNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="4"
              :width="0.32 * $bodyWidth"
              label="处理中"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.processingNum }}</span
                ><i
                  :class="
                    scope.row.processingNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.processingNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.processingNumSign > 0
                      ? "+" + scope.row.processingNumSign
                      : scope.row.processingNumSign < 0
                      ? "" + scope.row.processingNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="5"
              :width="0.32 * $bodyWidth"
              label="已处理"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.dealNum }}</span
                ><i
                  :class="
                    scope.row.dealNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.dealNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.dealNumSign > 0
                      ? "+" + scope.row.dealNumSign
                      : scope.row.dealNumSign < 0
                      ? "" + scope.row.dealNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="notAcceptNum"
              :key="6"
              :width="0.32 * $bodyWidth"
              label="已撤销"
              sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.cancelNum }}</span
                ><i
                  :class="
                    scope.row.cancelNumSign > 0
                      ? 'num-badge-blue'
                      : scope.row.cancelNumSign < 0
                      ? 'num-badge-orag'
                      : 'none'
                  "
                  >{{
                    scope.row.cancelNumSign > 0
                      ? "+" + scope.row.cancelNumSign
                      : scope.row.cancelNumSign < 0
                      ? "" + scope.row.cancelNumSign
                      : ""
                  }}</i
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import Pmap from "@/components/charts/map"; //地图
import LineChart from "@/components/charts/line2"; // 折线图
import Baidumap from "@/components/baidumap/index.vue";
import {
  complaintInfo,
  complaintSupervision,
  complaintAdd,
  complaintCumulative,
  complaintStatistics,
  complaintIndustryStatistics,
} from "@/api/homeacceptance";
import { cardType } from "@/api/common";
import Tab from "@/components/tab.vue";
import Tabhover from "@/components/tabhover.vue";
export default {
  name: "HomeAcceptance",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      lineChart1: false,
      lineChart2: false,
      hairpinType1: "",
      hairpinType2: "",
      options: [],
      forCodeData: {},
      tabPosition1: 1,
      tabPosition2: 1,
      radio1: "1",
      radio2: "1",
      tabData: [
        {
          name: "投诉",
          haripinType: 1,
        },
        {
          name: "处罚",
          haripinType: 2,
        },
      ],
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      legendData1: ["已投诉", "待审核", "待分配", "处理中", "已处理", "已撤销"],
      unitData: ["件", "件", "件", "件", "件", "件"],
      lineChartData1: {
        xAxisData: [],
        seriesData: [
          {
            name: "已受理",
            data: [],
          },
          {
            name: "待受理",
            data: [],
          },
        ],
      },
      legendData2: ["已投诉", "待审核", "待分配", "处理中", "已处理", "已撤销"],
      unitData2: ["件", "件", "件", "件", "件", "件"],
      lineChartData2: {
        xAxisData: [],
        seriesData: [
          {
            name: "已受理",
            data: [],
          },
          {
            name: "待受理",
            data: [],
          },
        ],
      },
      showAll: false,
      tableDataAll: [],
      loading1: false,
      loading2: false,
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      mapOptions: [],
      baidumapOptions: [],
      showmap: true,
      showbmap: true,
      liuchengdata: [
        {
          name: "已投诉",
          val: "0",
          com: "件",
        },
        {
          name: "待审核",
          val: "0",
          com: "件",
        },
        {
          name: "待分配",
          val: "0",
          com: "件",
        },
        {
          name: "处理中",
          val: "0",
          com: "件",
        },
        {
          name: "已处理",
          val: "0",
          com: "件",
        },
        {
          name: "已撤销",
          val: "0",
          com: "件",
        },
      ],
      liuchengdataindex: 0,
      newforCodeData: [
        {
          name: "较昨日",
          data: [0, 0, 0, 0, 0, 0],
          bdata: ["0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00"],
        },
        {
          name: "较上周",
          data: [0, 0, 0, 0, 0, 0],
          bdata: ["0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00"],
        },
        {
          name: "较上月",
          data: [0, 0, 0, 0, 0, 0],
          bdata: ["0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00"],
        },
        {
          name: "较上年",
          data: [0, 0, 0, 0, 0, 0],
          bdata: ["0.00", "0.00", "0.00", "0.00", "0.00", "0.00", "0.00"],
        },
      ],
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.complaintAdd();
    },
    radio2: function (val) {
      this.complaintCumulative();
    },

    hairpinType1: function (val) {
      this.complaintStatistics();
    },
    hairpinType2: function (val) {
      this.complaintIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    this.intData();
  },
  methods: {
    idiotone(val, ind) {
      this.liuchengdataindex = ind;
    },
    popupVal(val, row) {
      this.radio1 = val;
    },
    popupVal3(val, row) {
      this.radio2 = val;
    },
    popupVal6(val) {
      this.hairpinType1 = val;
    },
    popupVal8(val) {
      console.log(val, "------");
      this.hairpinType2 = val;
    },

    intData() {
      this.cardType();
      this.getMapOptionData();
      this.complaintSupervision();
      this.complaintAdd();
      this.complaintCumulative();
      this.complaintStatistics();
      this.complaintIndustryStatistics();
    },
    cardType() {
      cardType().then((res) => {
        res.data.unshift({ name: "全部", haripinType: "" });
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap = true;
      this.showmap = true;
      complaintInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //console.log('商户区域分布情况:',res)
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.compaintNum,
              compaintNum: item.compaintNum,
              pendingNum: item.pendingNum,
              distributionNum: item.distributionNum,
              processingNum: item.processingNum,
              dealNum: item.dealNum,
              cancelNum: item.cancelNum,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintSupervision() {
      complaintSupervision({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          console.log("投诉处罚情况统计:", res);
          this.liuchengdata = [
            {
              name: "已投诉",
              val: res.data.num,
              com: "件",
            },
            {
              name: "待审核",
              val: res.data.pendingNum,
              com: "件",
            },
            {
              name: "待分配",
              val: res.data.distributionNum,
              com: "件",
            },
            {
              name: "处理中",
              val: res.data.processingNum,
              com: "件",
            },
            {
              name: "已处理",
              val: res.data.dealNum,
              com: "件",
            },
            {
              name: "已撤销",
              val: res.data.cancelNum,
              com: "件",
            },
          ];
          this.newforCodeData = [
            {
              name: "较昨日",
              data: [
                res.data.num2yesterday,
                res.data.pendingNum2yesterday,
                res.data.distributionNum2yesterday,
                res.data.processingNum2yesterday,
                res.data.dealNum2yesterday,
                res.data.cancelNum2yesterday,
              ],
              bdata: [
                res.data.num2yesterdayRate,
                res.data.pendingNum2yesterdayRate,
                res.data.distributionNum2yesterdayRate,
                res.data.processingNum2yesterdayRate,
                res.data.dealNum2yesterdayRate,
                res.data.cancelNum2yesterdayRate,
              ],
            },
            {
              name: "较上周",
              data: [
                res.data.num2lastWeek,
                res.data.pendingNum2lastWeek,
                res.data.distributionNum2lastWeek,
                res.data.processingNum2lastWeek,
                res.data.dealNum2lastWeek,
                res.data.cancelNum2lastWeek,
              ],
               bdata: [
                res.data.num2lastWeekRate,
                res.data.pendingNum2lastWeekRate,
                res.data.distributionNum2lastWeekRate,
                res.data.processingNum2lastWeekRate,
                res.data.dealNum2lastWeekRate,
                res.data.cancelNum2lastWeekRate,
               ],
            },
            {
              name: "较上月",
              data: [
                res.data.num2lastMonth,
                res.data.pendingNum2lastMonth,
                res.data.distributionNum2lastMonth,
                res.data.processingNum2lastMonth,
                res.data.dealNum2lastMonth,
                res.data.cancelNum2lastMonth,
              ],
               bdata: [
                 res.data.num2lastMonthRate,
                res.data.pendingNum2lastMonthRate,
                res.data.distributionNum2lastMonthRate,
                res.data.processingNum2lastMonthRate,
                res.data.dealNum2lastMonthRate,
                res.data.cancelNum2lastMonthRate,
               ],
            },
            {
              name: "较上年",
              data: [
                res.data.num2lastYear,
                res.data.pendingNum2lastYear,
                res.data.distributionNum2lastYear,
                res.data.processingNum2lastYear,
                res.data.dealNum2lastYear,
                res.data.cancelNum2lastYear,
              ],
               bdata: [
                res.data.num2lastYearRate,
                res.data.pendingNum2lastYearRate,
                res.data.distributionNum2lastYearRate,
                res.data.processingNum2lastYearRate,
                res.data.dealNum2lastYearRate,
                res.data.cancelNum2lastYearRate,
               ],
            },
          ];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintAdd() {
      this.lineChart1 = true;
      complaintAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
        complainPunish: this.tabPosition1,
      })
        .then((res) => {
          this.lineChart1 = false;
          //console.log('投诉受理新增变化趋势',res)
          if (res.data != null) {
            this.lineChartData1 = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "已投诉",
                  data: res.data.compaintNum,
                },
                {
                  name: "待审核",
                  data: res.data.pendingNum,
                },
                {
                  name: "待分配",
                  data: res.data.distributionNum,
                },
                {
                  name: "处理中",
                  data: res.data.processingNum,
                },
                {
                  name: "已处理",
                  data: res.data.dealNum,
                },
                {
                  name: "已撤销",
                  data: res.data.cancelNum,
                },
              ],
            };
          } else {
            this.lineChartData1 = {
              xAxisData: [],
              seriesData: [],
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintCumulative() {
      this.lineChart2 = true;
      complaintCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      })
        .then((res) => {
          this.lineChart2 = false;
          //console.log('投诉受理累计势',res)
          if (res.data != null) {
            this.lineChartData2 = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "已投诉",
                  data: res.data.compaintNum,
                },
                {
                  name: "待审核",
                  data: res.data.pendingNum,
                },
                {
                  name: "待分配",
                  data: res.data.distributionNum,
                },
                {
                  name: "处理中",
                  data: res.data.processingNum,
                },
                {
                  name: "已处理",
                  data: res.data.dealNum,
                },
                {
                  name: "已撤销",
                  data: res.data.cancelNum,
                },
              ],
            };
          } else {
            this.lineChartData2 = {
              xAxisData: [],
              seriesData: [],
            };
          }
          console.log(this.lineChartData2, 11111);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    complaintStatistics() {
      this.loading1 = true;
      complaintStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        // console.log("投诉受理按城市/地区统计", res.data,this.regionType);
        console.log("预付码按城市/地区统计-二级", res);
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        if (res.data != "") {
          this.tableDataAll = [];
          this.$nextTick(() => {
            this.tableData1 = res.data;
            this.tableDataAll = this.tableData1.slice(0, 10);
            this.showAllData();
          });
        } else {
          this.tableData1 = [];
          this.tableDataAll = [];
        }
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    //确保row-key不重复
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      complaintStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        resolve(res.data || []);
      });
    },
    complaintIndustryStatistics() {
      this.loading2 = true;
      complaintIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data;
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      complaintIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('投诉受理按领域/行业统计-二级',res)
        let data = null;
        if (res.data == "") {
          data = [];
        } else {
          data = res.data;
        }
        resolve(data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    Pmap,
    LineChart,
    Tab,
    Tabhover,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomeAcceptance {
  // 四色码
  .idiot-one {
    display: flex;
    flex-wrap: wrap;
    //height: 420px;
    margin-bottom: 30px;
    background: white;
    font-size: 26px;
    color: #232a24;

    .boxtop {
      width: 100%;
      height: 128px;
      border-bottom: 1px solid #ececec;
      display: flex;
      align-items: center;
      //justify-content: center;
      //padding: ;
      .boxtopchild {
        width: 25%;
        height: 100%;
        &.middle {
          width: 14%;
        }
        & > div {
          display: flex;
          align-items: center;
          width: 100%;
          height: 50%;
          span {
            color: #1d67ff;
            font-size: 24px;
          }
        }
        & > div:nth-child(2) {
          font-size: 24px;
        }
      }
    }
    .boxval {
      width: 100%;
      .boxvalchild {
        width: 100%;
        height: 62px;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eeeeee;
        .boxvalchildname {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .t {
          color: #ffba00;
          font-weight: bold;
        }
        .b {
          color: #59d2f3;
          font-weight: bold;
        }
      }
    }
    .idiot-one-box {
      width: 100%;
      height: 585px;
      // position: relative;
      .idiot-one-box-top {
        width: 650px;
        height: 140px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin: 50px auto 0 auto;
      }
      .idiot-one-box-top::-webkit-scrollbar {
        display: none;
      }
      .idiot-one-top-child {
        width: 170px;
        height: 75px;

        // margin: 1px;
        display: inline-block;
        position: relative;
        font-size: 26px;
        // color:#1d67ff;
        color: #232a24;
        font-weight: 200;
        .idiot-one-top-child-val {
          display: flex;
          align-items: center;
          justify-content: center;
          .idiot-one-top-child-com {
            font-size: 18px;
            // line-height: 30px;
          }
        }
        .idiot-one-top-child-name {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .idiot-one-top-child-icon {
          width: 18px;
          height: 18px;
          position: absolute;
          top: 90px;
          left: 76px;
          background: #1e68ff;
          border-radius: 50%;
        }
        .idiot-one-top-child-newicon {
          width: 32px;
          height: 32px;
          position: absolute;
          top: 83px;
          left: 69px;
          background: rgba(29, 103, 255, 0.5);
          border-radius: 50%;
        }
        .idiot-one-top-child-line {
          position: absolute;
          top: 98px;
          left: 0;
          width: 100%;
          height: 3px;
          background: #1e68ff;
        }
        &:nth-child(1) {
          .idiot-one-top-child-line {
            width: 50%;
            left: 50%;
          }
        }
        &:last-child {
          .idiot-one-top-child-line {
            width: 50%;
          }
        }
      }
      .toptab_val_box {
        width: 88%;
        height: 334px;
        // background: red;
        display: flex;
        flex-wrap: wrap;
        margin: 10px 6% 0 6%;
        .toptab_val_child {
          width: 50%;
          height: 50%;
          color: #232a24;
          .toptab_val_child_name {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 24px;
          }

          .toptab_val_child_val {
            width: 100%;
            height: 68px;
            line-height: 68px;
            justify-content: center;
            font-size: 40px;
            display: flex;
            .toptab_val_child_com {
              font-size: 24px;
              line-height: 78px;
              margin-left: 5px;
            }
            .t {
              color: #dd3f58;
              font-weight: bold;
              font-size: 12px;
              margin: 30px 0 0 5px;
            }
            .b {
              color: #00b19a;
              font-weight: bold;
              font-size: 12px;
              margin: 30px 0 0 5px;
            }
          }
          .toptab_val_child_bfb{
            width: 100%;
            height: 45px;
            line-height: 45px;
            justify-content: center;
            font-size: 20px;
            display: flex;
            color:rgb(29, 103, 255);
             .toptab_val_child_com {
              font-size: 14px;
              line-height: 45px;
            }
          }
        }

        .toptab_val_child:nth-child(1) {
          border-bottom: 1px dashed #ececec;
          border-right: 1px dashed #ececec;
        }
        .toptab_val_child:nth-child(2) {
          border-bottom: 1px dashed #ececec;
        }
        .toptab_val_child:nth-last-child(2) {
          border-right: 1px dashed #ececec;
          .toptab_val_child_name {
            margin-top: 20px;
          }
        }
        .toptab_val_child:nth-last-child(1) {
          .toptab_val_child_name {
            margin-top: 20px;
          }
        }
      }
    }

    .box {
      width: 33%;
      text-align: center;
      .box-header {
        color: #b4b9c1;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        span:nth-child(1) {
          font-size: 18px;
        }
        span:nth-child(2) {
          font-size: 30px;
          margin: 0 10px;
        }
      }
      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
    }
  }
  .pie-box {
    background: url("../assets/imgs/pie-bg2.png") no-repeat;
    background-size: 100%;
    margin-bottom: 0;
    .echarts-box {
      position: relative;
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
}
</style>
